import * as React from "react";
import { Link } from "gatsby";

// import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";
import { graphql } from "gatsby";

export default function OlderChests({ data }) {
  return (
    <Layout>
      <Helmet title="Older Chests | Damien Rice" />

      <h1>Older Chests</h1>

      <h2>Damien Rice</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Img fluid={data.file.childImageSharp.fluid} />

      <Columns columns="2">
        <Verse>
          <p>Older chests reveal themselves</p>
          <p>Like a crack in a wall</p>
          <p>Starting small, and grow in time</p>
          <p>And we always seem to need the help</p>
          <p>Of someone else</p>
          <p>To mend that shelf</p>
          <p>Too many books</p>
          <p>Read me your favorite line</p>
        </Verse>
        <Verse>
          <p>Papa went to other lands</p>
          <p>And he found someone who understands</p>
          <p>The ticking, and the western man's need to cry</p>
          <p>He came back the other day, you know</p>
          <p>Some things in life may change</p>
          <p>And some things</p>
          <p>They stay the same</p>
        </Verse>
        <Chorus>
          <p>Like time, there's always time</p>
          <p>On my mind</p>
          <p>So pass me by, I'll be fine</p>
          <p>Just give me time</p>
        </Chorus>
        <Verse>
          <p>Older gents sit on the fence</p>
          <p>With their cap in hand</p>
          <p>Looking grand</p>
          <p>They watch their city change</p>
          <p>Children scream, or so it seems,</p>
          <p>Louder than before</p>
          <p>Out of doors, and into stores with bigger names</p>
        </Verse>
        <Verse>
          <p>Mama tried to wash their faces</p>
          <p>But these kids they lost their graces</p>
          <p>And daddy lost at the races too many times</p>
          <p>She broke down the other day, yeah you know</p>
          <p>Some things in life may change</p>
          <p>But some things they stay the same</p>
        </Verse>
        <Chorus>
          <p>Like time, there's always time</p>
          <p>On my mind</p>
          <p>So pass me by, I'll be fine</p>
          <p>Just give me time</p>
          <p>Time, there's always time</p>
          <p>On my mind</p>
          <p>Pass me by, I'll be fine</p>
          <p>Just give me time</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}

export const query = graphql`
  query {
    file(relativePath: { eq: "older-chests.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;